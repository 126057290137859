import React from 'react';
import { inject } from 'mobx-react';
import { BasicSideBar } from '@izi-logistics/common/lib/components';
import { BasicSideBarMenuItem } from '@izi-logistics/common/lib/components';
import { MainStoreProps } from '@izi-logistics/common/lib/models';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import LayersIcon from '@material-ui/icons/Layers';

import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import RestoreOutlinedIcon from '@material-ui/icons/RestoreOutlined';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import EditIcon from '@material-ui/icons/Edit';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import BusinessIcon from '@material-ui/icons/Business';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { client } from '../../api/apolloClient';
import { WarehouseIcon, DashboardIcon, InProgressIcon } from '@izi-logistics/common/lib/components/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';
const isHideInvoice = process.env.REACT_APP_VERSION === '2.1.0';

const SidebarMenu = inject('store')(({ store }: MainStoreProps) => {
  const langMenu = useTranslation(KEYS.MENU);
  const langApp = useTranslation(KEYS.APP);

  const menu: BasicSideBarMenuItem[] = [
    {
      icon: <ControlPointIcon />,
      title: langMenu.t('menu_sidebar_new_order_title'),
      path: '/orders/create',
      _id: 'menu-sidebar-new-order',
    },
    {
      icon: <DescriptionOutlinedIcon />,
      title: langMenu.t('menu_sidebar_open_orders_title'),
      path: '/open-orders',
      _id: 'menu-sidebar-open-orders',
    },
    {
      icon: (
        <span>
          <InProgressIcon title={langMenu.t('menu_sidebar_in_progress_title')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_in_progress_title'),
      path: '/in-progress',
      _id: 'menu-sidebar-in-progress',
    },

    {
      icon: <EditIcon id={'edit-btn'} />,
      title: langMenu.t('menu_sidebar_draft_title'),
      path: '/drafts',
      _id: 'menu-sidebar-drafts',
    },
    {
      icon: <FeaturedPlayListIcon />,
      title: langMenu.t('menu_sidebar_template_title'),
      path: '/templates',
      _id: 'menu-sidebar-templates',
    },
    {
      icon: <RestoreOutlinedIcon />,
      title: langMenu.t('menu_sidebar_archive_title'),
      path: '/archive',
      _id: 'menu-sidebar-archive',
    },
    {
      icon: <DateRangeIcon />,
      title: langMenu.t('all-approved'),
      path: '/all-approved',
      _id: 'menu-sidebar-all-approved',
    },
    {
      icon: <GroupOutlinedIcon />,
      title: langMenu.t('menu_sidebar_team_title'),
      path: '/team',
      _id: 'menu-sidebar-team',
    },
    // {
    //   icon: <LocalShippingIcon />,
    //   title: langMenu.t('menu_sidebar_carriers'),
    //   path: '/carriers',
    // },
    // {
    //   icon: <SupervisorAccountIcon />,
    //   title: langMenu.t('menu_sidebar_customers'),
    //   path: '/customers',
    // },
    {
      icon: <BusinessIcon />,
      title: langMenu.t('menu_sidebar_companies_title'),
      path: '/companies',
      _id: 'menu-sidebar-companies',
    },
    // {
    //   icon: (
    //     <span>
    //       <ChannelChatIcon title={langMenu.t('menu_sidebar_chat_title')} />
    //     </span>
    //   ),
    //   title: langMenu.t('menu_sidebar_chat_title'),
    //   path: '/chats',
    // },

    !isHideInvoice && {
      icon: <AllInboxIcon />,
      title: langApp.t('main_finance'),
      path: '/finance',
      _id: 'menu-sidebar-finance',
    },
    {
      icon: <LayersIcon />,
      title: langApp.t('main_terms'),
      path: '/terms',
      _id: 'menu-sidebar-terms',
    },
    {
      icon: (
        <span>
          <WarehouseIcon title={langMenu.t('menu_sidebar_warehouse') as string} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_warehouse'),
      path: '/warehouses',
      _id: 'menu-sidebar-warehouses',
    },

    {
      icon: (
        <span>
          <DashboardIcon title={langMenu.t('menu_sidebar_dashboard')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_dashboard'),
      path: '/dashboard',
      _id: 'menu-sidebar-dashboard',
    },
  ].filter((e) => e);

  return <BasicSideBar menu={!store.accountStore.currentCompany._id ? [] : menu} config={{ client }} />;
});
export default SidebarMenu;
