import React from 'react';
import { Grid } from '@material-ui/core';
import { TextOverflow } from '@izi-logistics/common/lib/components';
import { dateViewFormat } from '@izi-logistics/common/lib/components/DateView';
import { theme } from '@izi-logistics/common/lib/theme';
import { inject } from 'mobx-react';
import { DeleteDialog } from '@izi-logistics/common/lib/components';
import { ACTION_CHANGE } from '@izi-logistics/common/lib/components/context.store.enums';
import { onPreventEvent } from '@izi-logistics/common/lib';
import CompanyItemForwarder from '@izi-logistics/common/lib/pages/Companies/CompanyItemForwarder';
import { client } from '../../api/apolloClient';
import { OrderLocation } from '@izi-logistics/common/lib/models';
import MenuListItem from '@izi-logistics/common/lib/components/MenuListItem';

import CompanyFormModal from '@izi-logistics/common/lib/components/Account/CompanyFormModal';
import { StyledBadge } from '@izi-logistics/common/lib/components/StyledBadge';
import { FlagComponent } from '@izi-logistics/common/lib/components/Flag';
import BaseTooltip from '@izi-logistics/common/lib/components/BaseTooltip';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import { USER_ROLE_TOOLS } from '@izi-logistics/common/lib/models/UserRoles';
import { AppType } from '@izi-logistics/common/lib/pages/Quotes/Documents/DocumentItemVisibleTo';

export default inject('store')(
  ({
    record,
    onChange,
    expandContent,
    handleExpandClick,
    store: {
      accountStore: { currentUser, currentCompany },
    },
  }: any) => {
    const langForm = useTranslation(KEYS.FORM);
    const onDelete = async () => {
      await currentCompany.deleteItem(record);
      if (onChange) onChange(record, ACTION_CHANGE.DELETED);
    };
    const canEdit = currentUser.rolesV1.find((e) =>
      e.tools.general.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data)
    );

    const createdBy = record.createdBy?._id === currentUser._id;
    const createdByCompany = record.createdBy?.company?._id?.toString() === currentCompany._id;

    return (
      <Grid container style={{ padding: theme.spacing(0.5, 1) }} onClick={handleExpandClick}>
        <Grid item xs={12} md={2} container alignItems={'center'}>
          <BaseTooltip title={langForm.t('form_list_members_total')}>
            <StyledBadge badgeContent={record.totalUsers} color="primary">
              <div style={{ width: 14, height: 34 }} />
            </StyledBadge>
          </BaseTooltip>
          <TextOverflow style={{ maxWidth: 'calc(100% - 20px)' }}>{record.name || '-'}</TextOverflow>
        </Grid>
        <Grid item xs={12} md={2} container alignItems={'center'}>
          {record.vatCode || '-'}
        </Grid>
        <Grid item xs={12} md={1} container alignItems={'center'}>
          {record.companyCode || '-'}
        </Grid>
        <Grid item xs={12} md={3} container alignItems={'center'}>
          {record.addressV1 && record.addressV1.country && (
            <FlagComponent country={record.addressV1.country} style={{ marginRight: theme.spacing(0.5) }} />
          )}
          <TextOverflow style={{ maxWidth: 'calc(100% - 44px)' }}>
            {(record.addressV1 && OrderLocation.create({ address: record.addressV1 }).fullAddress) || '-'}
          </TextOverflow>
        </Grid>

        <Grid item xs={12} md={1} container alignItems={'center'}>
          <TextOverflow variant={'caption'}>
            {`${record.forwarderOrderMarkUpPercentage || 0}(%)/${record.forwarderOrderMarkUpMinPrice || 0}(${
              record.forwarderOrderMarkUpMinPriceCurrency || ''
            })/${record.forwarderOrderMarkUpMaxPrice || 0}(${record.forwarderOrderMarkUpMaxPriceCurrency || ''})`}
          </TextOverflow>
        </Grid>

        <Grid item xs={12} md={1} container alignItems={'center'}>
          <TextOverflow variant={'caption'}>
            {[record.createdAt]
              .filter((e) => e)
              .map((e) => dateViewFormat(e, 1))
              .join('/')}
          </TextOverflow>
        </Grid>

        <Grid item xs={12} md={2} container alignItems={'center'} justify={'flex-end'} style={{ width: 'initial' }}>
          <Grid onClick={onPreventEvent} style={{ width: 'calc(100% - 120px)' }}>
            <CompanyItemForwarder record={record} config={{ client }} onChange={onChange} />
          </Grid>
          <AppType type={record.type} />
          <div style={{ padding: theme.spacing(0, 0.5) }}>
            <MenuListItem
              show
              list={[
                ((canEdit || createdByCompany || createdBy) && {
                  content: <CompanyFormModal label withText record={record} onChange={onChange} />,
                  available: true,
                }) ||
                  null,
                (((canEdit && createdByCompany) || createdBy) && {
                  content: <DeleteDialog withoutWrapper withText onOk={onDelete} v1 value={record.name} />,
                  available: true,
                }) ||
                  null,
              ].filter((e) => e)}
            />
          </div>

          {expandContent}
        </Grid>
      </Grid>
    );
  }
);
