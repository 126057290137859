import { CompletePaperLayout } from '@izi-logistics/common/lib/components/CompletePaperLayout';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import SidebarMenu from './components/common/SidebarMenu';
import { client } from './api/apolloClient';
import Account from './pages/Account/Account';
import Team from './pages/TeamPage';
import ForwarderOrderList from './pages/Orders/List';
import Companies from './pages/Companies';
import ChatContentComponent from './pages/Chat';
import InvoiceForm from './pages/Finance/Invoices/Form';
import CostForm from './pages/Finance/Costs/Form';
import DashboardPage from './pages/Dashboard';
import Finance from './pages/Finance';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import OrderForm from './pages/Orders/Form';
import Issues from './pages/Issues';
import { i18n, KEYS } from '@izi-logistics/common/lib/i18n';
import MainTabsRoutes, { WarehousePage } from './pages/Warehouse';

const Terms = React.lazy(() => import('./pages/Terms'));

const WarehouseRoute = () => (
  <Switch>
    <Route path="/warehouses/:warehouseId" component={MainTabsRoutes} />
    <Route exact path="/warehouses" component={WarehousePage} />
  </Switch>
);

export default function RootRoutes() {
  const routes = (
    <Switch>
      <Route exact path="/orders/:orderId/restore" component={OrderForm} />
      <Route exact path="/orders/create" component={OrderForm} />
      <Route exact path="/orders/:orderId" component={OrderForm} />
      <Route exact path="/finance/invoices/create" component={() => <InvoiceForm key={'invoice-create'} />} />
      <Route
        exact
        path="/finance/invoices/:invoiceId/credit"
        component={() => <InvoiceForm key={'invoice-credit'} />}
      />
      <Route exact path="/finance/invoices/:invoiceId" component={() => <InvoiceForm key={'invoice-form'} />} />
      <Route
        exact
        path="/finance/invoices-templates/:invoiceId"
        component={() => <InvoiceForm key={'invoices-templates-form'} />}
      />
      <Route exact path="/finance/costs/create" component={() => <CostForm key={'cost-create'} />} />
      <Route
        exact
        path="/finance/costs/:invoiceId/request-refund"
        component={() => <CostForm key={'request-refund'} />}
      />
      <Route exact path="/finance/costs/:invoiceId" component={() => <CostForm key={'cost'} />} />
      <Route exact path="/finance/costs-templates/:invoiceId" component={() => <CostForm key={'costs-templates'} />} />
      <Route path="/finance" component={Finance} />
      <Route
        exact
        path="/terms"
        component={(p) => (
          <PreloadingComponent>
            <Terms {...p} />
          </PreloadingComponent>
        )}
      />

      <Route exact path="/change-password" component={SetPassword} />
      <Route exact path="/account/details" component={Account} />
      <Route exact path="/account/settings" component={Account} />
      <Route path="/account">
        <Redirect to={'/account/details'} />
      </Route>
      <Route exact path="/team" component={Team} />
      <Route exact path="/issues" component={Issues} />
      <Route exact path="/companies" component={Companies} />
      <Route exact path="/archive" component={(p: any) => <ForwarderOrderList {...p} key={p.match.url} />} />
      <Route exact path="/all-approved" component={(p: any) => <ForwarderOrderList {...p} key={p.match.url} />} />
      <Route exact path="/drafts" component={(p: any) => <ForwarderOrderList {...p} key={p.match.url} />} />
      <Route path="/chats" component={ChatContentComponent} />
      <Route
        exact
        path="/templates"
        component={(p: any) => <ForwarderOrderList {...p} searchFields={['templateName']} key={p.match.url} />}
      />

      <Route path="/in-progress" exact component={(p: any) => <ForwarderOrderList {...p} key={p.match.url} />} />
      {[
        {
          path: `/in-progress/no-transport`,
          configDetails: {
            title: i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'in_progress_has_not_transport'),
            defaultFilterV1: { fields: [{ name: 'process', value: [6] }] },
            config: { id: 'sorting_hasTransport' },
          },
        },
        {
          path: `/in-progress/no-pickup-time`,
          configDetails: {
            title: i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'in_progress_has_not_time_pickup'),
            defaultFilterV1: { fields: [{ name: 'process', value: [8] }] },
            config: { id: 'sorting_hasPickupTime' },
          },
        },
        {
          path: `/in-progress/no-delivery-time`,
          configDetails: {
            title: i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'in_progress_has_not_time_delivery'),
            defaultFilterV1: { fields: [{ name: 'process', value: [10] }] },
            config: { id: 'sorting_hasDeliveryTime' },
          },
        },
        {
          path: `/in-progress/no-loaded`,
          configDetails: {
            title: i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'in_progress_has_not_loaded'),
            defaultFilterV1: { fields: [{ name: 'process', value: [12] }] },
            config: { id: 'sorting_hasLoaded' },
          },
        },
        {
          path: `/in-progress/no-un-loaded`,
          configDetails: {
            title: i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'in_progress_has_not_un_loaded'),
            defaultFilterV1: { fields: [{ name: 'process', value: [14] }] },
            config: { id: 'sorting_hasUnloaded' },
          },
        },
      ].map((e) => (
        <Route
          key={e.path}
          path={e.path}
          exact
          component={(p: any) => (
            <ForwarderOrderList
              {...p}
              key={p.match.url}
              configDetails={{
                ...e.configDetails,
                filters: { disableProcess: 1 },
              }}
            />
          )}
        />
      ))}
      <Route path="/warehouses" component={WarehouseRoute} />
      <Route path="/dashboard" component={DashboardPage} />
      <Route exact path="/open-orders" component={(p: any) => <ForwarderOrderList {...p} key={p.match.url} />} />
      <Route path="*">
        <Redirect to="/dashboard" />
      </Route>
    </Switch>
  );
  return <CompletePaperLayout config={{ client }} sideBarMenuContent={<SidebarMenu />} pageContent={routes} />;
}
