import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TabPanel, a11yProps } from '@izi-logistics/common/lib/components';
import { useTranslation } from 'react-i18next';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import { FetchAdminUsers } from '@izi-logistics/common/lib/graphql';
import { client } from '../../api/apolloClient';
import { theme } from '@izi-logistics/common/lib/theme';
import { InviteTeamMembers } from '@izi-logistics/common/lib/graphql';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
const Terms = React.lazy(() => import('@izi-logistics/common/lib/pages/Terms'));
const UsersList = React.lazy(() => import('@izi-logistics/common/lib/pages/Users'));

export default ({ record }) => {
  const [tab, setTab] = React.useState(0);
  const langApp = useTranslation(KEYS.APP);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const sections = [
    {
      label: langApp.t('team_label'),
      content: (
        <PreloadingComponent>
          <UsersList
            v1
            title={langApp.t('team_label')}
            config={{
              client,
              query: FetchAdminUsers,
              variables: { fields: [{ name: 'company', value: record._id }] },
            }}
            addMemberProps={{
              hideCompany: true,
              onAddUserCompany: (users) =>
                client.mutate({
                  mutation: InviteTeamMembers,
                  variables: {
                    input: users.map((user) => ({
                      user: {
                        phone: user.phone,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        roles: user.roles,
                        email: user.email,
                        appType: [record.type],
                      },
                      company: record._id,
                    })),
                  },
                }),
            }}
          />
        </PreloadingComponent>
      ),
    },
    {
      label: langApp.t('main_terms'),
      content: (
        <PreloadingComponent>
          <Terms
            v1
            config={{
              client,
            }}
            defaultFilter={{ fields: [{ name: 'companyRelated', value: record._id }] }}
            defaultValue={{ companyRelated: record._id }}
          />
        </PreloadingComponent>
      ),
    },
  ];
  return (
    <Grid container direction={'column'}>
      <Divider style={{ width: '100%' }} />
      <Tabs value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary">
        {sections.map((e, index) => (
          <Tab key={e.label} label={e.label} color={'primary'} {...a11yProps(0)} />
        ))}
      </Tabs>

      {sections.map((e, index) => (
        <TabPanel value={tab} index={index}>
          <Grid container>
            <Divider style={{ width: '100%', marginBottom: theme.spacing(1) }} />
            <Grid container style={{ padding: theme.spacing(0, 2) }}>
              {e.content}
            </Grid>
          </Grid>
        </TabPanel>
      ))}
    </Grid>
  );
};
