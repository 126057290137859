import React from 'react';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const RegisterPage = React.lazy(() => import('@izi-logistics/common/lib/pages/RegisterPage/RegisterPage'));

const Register = () => (
  <PreloadingComponent>
    <RegisterPage appType={'FORWARDER'} />
  </PreloadingComponent>
);
export default Register;
