import React from 'react';
import { ExpandedRow } from '@izi-logistics/common/lib/components/BasicList/Body/ExpandedRow';

import CompanyItemHeader from './CompanyItemHeader';
import CompanyItemBody from './CompanyItemBody';

export default function CompanyItem({ config, record, onChange }) {
  return (
    <ExpandedRow
      {...{ record, onChange }}
      config={config}
      body={<CompanyItemBody record={record} />}
      header={(expandContent, handleExpandClick) => (
        <CompanyItemHeader
          record={record}
          onChange={onChange}
          expandContent={expandContent}
          handleExpandClick={handleExpandClick}
        />
      )}
    />
  );
}
