import React from 'react';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const SetPasswordPage = React.lazy(() => import('@izi-logistics/common/lib/pages/SetPasswordPage'));

const SetPassword = () => (
  <PreloadingComponent>
    <SetPasswordPage />
  </PreloadingComponent>
);
export default SetPassword;
